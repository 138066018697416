/* Override Bootstrap containers */
#map {
	height: 450px;
}
body {
    overflow-x: hidden;
}

@media (min-width: 576px) {
	.container {
		max-width: 544px;
	}
	.container.no-gutter {
		max-width: 100%;
	}
	#map {
		height: 500px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 740px;
	}
	.container.no-gutter {
		max-width: 100%;
	}
	#map {
		height: 550px;
	}
}

@media (min-width: 1092px) {
	.container {
		max-width: 1060px;
	}
	.container.no-gutter {
		max-width: 1092px;
	}
	#map {
		height: 550px;
	}
}

@media (min-width: 1300px) {
	.container {
		max-width: 1260px;
	}
	.container.no-gutter {
		max-width: 1300px;
	}
	#map {
		height: 575px;
	}
}

.container-wide {
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}
@media (min-width: 767px) {
    .container-wide {
        padding: 0 30px;
    }
}

@media (min-width: 1920px) {
    .container-wide {
        max-width: 1920px;
    }
}

.map-key {
    margin-top: 10px;
    padding: 3px;
    border: 1px solid rgba(50,50,50,.1);
    text-align: right;
}
img.key-pin {
    margin: 3px 15px;
}


a.pad-anchor{display:block; position:relative; top:-350px; visibility:hidden;}

/* Above tyhe fold stuff goes here */
html, body, #page, #mast .row, #mast .fluid-container { height:100%; margin:0; }
body { width:100%; overflow-x: hidden; }
select[multiple] {
    height: 38px;
}

#page.support {
    height: auto;
}

#page.full {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.has-error {
    border-color: red;
}

.btn-lg, .btn-group-lg > .btn {
    border-radius: 0;
}

.embed-responsive img {
    width:100%;
}

/* Structure */
.container.no-gutter,
.container-fluid.no-gutter {
	padding-right: 0;
	padding-left: 0;
}

.container.no-gutter .row,
.container-fluid.no-gutter .row {
	margin-left: 0;
	margin-right: 0;
}

.no-gutter [class*='col-']:not(:first-child),
.no-gutter [class*='col-']:not(:last-child) {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}

/* Top */

#top .navbar {
    margin: 0;
    padding: 0;
}

#top header {
	position: relative;
	width: 100%;
	z-index: 6;
    background: #562729;

    // .container {
    //     @media screen and (min-width: 544px) and (max-width:1091px) {
    //         width: calc(100% - 147px);
    //         margin: 0;
    //     }
    // }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: normal;
    line-height: 1.1;
    color: inherit;
	font-family: 'Raleway';
}

@media screen and (min-width: 768px) {
	#top {
		/*padding-bottom: 56px;*/
		position: fixed;
		z-index: 5;
		width: 100%;
	}
	#top header {
    	height: 45px;
	}
	.navbar-static-top div.navbar-toggleable-xl,
	.navbar-static-top div.navbar-toggleable-lg,
	.navbar-static-top div.navbar-toggleable-xs,
	.navbar-static-top div.navbar-toggleable-md,
	.navbar-static-top div.navbar-toggleable-sm {
		display: inline-block;
		float: right;
	}
}

@media screen and (min-width: 1092px) {
    #top header {
    	height: 79px;
	}
}

/* Extra large devices (large desktops, 1300px and up) */
@media (min-width: 1300px) {

}

#top .navbar-toggler {
    border: 0px none;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 1em;
    padding: 10px 20px;
    width: 100%;
    outline: medium none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 999;

    @media screen and (min-width: 544px) {
        width: auto;
    }
}

ul#primary-menu {
    list-style: outside none none;
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 0px;
    text-align:right;

    li.menu-item {
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 5;

        @media screen and (min-width: 1092px){
            width: auto;
        }
	}

}


@media screen and (min-width: 768px){

	#mast {
		height: 100%;
	}

	#mid,
	#mast {
    	padding-top: 124px;
	}

	.home #mid {
		padding-top: 0;
		min-height: 360px;
	}


}

@media screen and (min-width: 768px){
    #mid {
        padding-top: 183px;
    }
}

@media screen and (min-width: 1092px){
    #mid, #mast {
        padding-top: 146px;
    }
}

.home #mid {
	background:#FFF;
	box-shadow: 0 0 30px rgba(0,0,0,.2);
	position: relative;
	z-index: 1;
}

a.navbar-brand {
    display: block;
    padding: 0;

    img {
        margin: -54px 0 0;
        position: absolute;
        width: 190px;

        @media screen and (min-width: 544px){
            margin: -30px 0 0;
        }

        @media screen and (min-width: 992px){
            margin-top: 5px;
        }

        @media screen and (min-width: 1092px ) {
            margin: -54px 0 0;
        }
    }
}

#searchbar form {
    padding: 10px;
}

ul#primary-menu {
    background: #562729;

    @media screen and (min-width: 1200px){
        padding-left:130px;
    }

    @media screen and (min-width: 1400px){
        padding-left:180px;
    }
}

ul#primary-menu li.menu-item a {
    display:block;
	font-family: 'Raleway';
    padding:15px 15px 12px;
	color:#FFF;
	text-transform: uppercase;
	text-align: center;
    font-size: 14px;

    @media screen and (min-width: 1092px){
        font-size: 12px;
        padding: 30px 15px 27px;
    }

    @media screen and (min-width: 1300px){
        font-size: 14px;
    }
}

/* Large devices (desktops, 1092px and up) */
@media (max-width:1091px){
	.collapse {
		display: none !important;
	}
	.collapsing {
		display: block !important;
	}
	.collapse.in {
		display: block !important;
	}
}

ul#primary-menu li.menu-item a:focus,
ul#primary-menu li.menu-item a:active,
ul#primary-menu li.menu-item a:hover {
	text-decoration: none;
}


@media screen and (min-width: 1092px){
	ul#primary-menu>li.current-menu-item a,
	ul#primary-menu>li.menu-item:hover a {
		padding: 20px 15px 27px;
	}

	ul#primary-menu>li.current-menu-item:before,
	ul#primary-menu>li.menu-item:hover:before {
		content: "";
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #c41230;
		position: relative;
		top: 0;
		left: 50%;
		margin-left: -10px;
		display: block;
	}

	ul#primary-menu li.menu-item:hover:before {
		border-top-color: #e3e0d3;
	}


}

.navbar-toggler .icon-box {
	height: 24px;
	width: 32px;
	display: inline-block;
	margin:0;
}

.navbar-toggler .icon-bar:first-child,
.navbar-toggler .icon-bar:last-child,
.navbar-toggler .icon-bar,
.navbar-toggler.collapsed .icon-bar,
.navbar-toggler.collapsed:focus .icon-bar {
	visibility:visible;
	opacity:1;
	width: 23px;
    margin: 5px 0;
    display: block;
    height: 2px;
    background-color: #FFF;
}

.navbar .navbar-toggler.collapsed .icon-bar,
.navbar .navbar-toggler .icon-bar {
	background-color: #fff;
}

.navbar-static-top {
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

#top ul.sub-menu {
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0 10px 10px;
    border-radius: 10px;
    padding: 10px;
    background-color: #3d1b1c;
    z-index: 10;
}

#top nav li.menu-item ul.sub-menu li a {
	font-size: 16px;
	padding: 12px 10px;
}

/* Top Phone */
.top-phone {
    display: block;

    &.mobile {
        @media screen and (min-width:544px){
            display: none;
        }

        a {
            padding: 8px 10px;
        }
    }

    &.desktop {
        display: none;

        @media screen and (min-width:544px){
            display: inline-block;
            float: right;
        }

        a {
            padding: 10px 20px 16px 20px;
            line-height: 1em;

            @media screen and (min-width: 1092px){
                padding: 27px 0 27px 30px;
            }
        }
    }

    a {
        display: block;
        width: 100%;
        text-align: right;
        background: rgba(0,0,0,.1);
        color: #e3e0d3;
        font-size: 19px;
        font-weight: bold;

        @media screen and (min-width: 1092px){
            background-color: transparent;
        }

        &:hover,
        &:focus {
            color: #e3e0d3;
            text-decoration: none !important;
        }
    }
}

/** Search Bar **/
#searchbar {
	background: #e3e0d3;
    min-height: 64px;
    position: relative;
    padding-bottom: 8px;
    padding-top: 30px;
    /*z-index: 4;*/

    @media screen and (min-width: 543px){
        padding: 60px 10px 10px;
    }

    @media screen and (min-width: 768px){
        padding: 10px 10px 10px 220px;
    }

    @media screen and (min-width: 1600px){
        padding: 10px 10px 10px 10px;
    }
}



#searchbar form {
	position: relative;
    z-index: 5;
    margin: 0;
}

.custom-select {
    border-radius: 0;
    width: 100%;
}

#searchbar form .parentoption {
	font-weight: bold !important;
}

#searchbar label {
	color:#999;
	text-transform: uppercase;
	font-size: 14px;
	padding-bottom: 10px;
    font-weight: bold;
}

#searchbar .checkbox label,
#searchbar select label {
	color:#555;
	text-transform: lowercase;
	font-size: 14px;
	padding: 2px 5px;
}

.search-form-label {
    padding: 4px 10px 4px;
    color: #a9a490;
    font-size: 14px;
    font-weight: bold;
	line-height: 1.1em;
    margin: 0;
    text-align:right;

    span {
        display: block;

        @media screen and (min-width: 543px){
            display: inline-block;
        }
    
        @media screen and (min-width: 992px){
            display: block;
        }
    }


}

#searchbar .search-select {
    line-height: 1.45 !important;
    button {
        height: 38px;
    }
}

.area-select option {
    font-weight: bold;
}

.area-select optiongroup option {
    font-weight: normal;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: none;
  padding: 0; }

.advanced-menu {
	background: #FFF;
	padding:30px 0;
	border-bottom: 1px solid #ddd;
	position: relative;
    z-index: 1;
}

#mid .pagination a { text-decoration: none; }
#mid a.disabled { text-decoration: none; color: #999; }
#mid .pagination .active a { color: #FFF; }

/** Home page buttons **/
.huge-button {
	padding:0 !important;
	background-size:cover;
	height:200px;
	background-color: #eaeaea;
	background-size: cover;
	background-position: 50% 26%;
}

.huge-button * {
	transition: all ease-out .5s;
	-webkit-transition: all ease-out .5s;
	-moz-transition: all ease-out .5s;
	line-height: 1em !important;
}

a.huge-button-link {
	z-index: 3;
    height: 100%;
    display: block;
    text-align: center;
	color:#fff !important;
	text-decoration: none !important;

	background-color: rgba(0,0,0,.65);
}

a.huge-button-link:hover,
a.huge-button-link:focus {
	background-color: rgba(0,0,0,0);
}

a.huge-button-link .line {
	width:0;
	margin:40px 50% 0;
	border-bottom:1px solid #FFF;
	position: relative;
    top: 50%;
}

a.huge-button-link:hover .line,
a.huge-button-link:focus .line {
	width: 50%;
    margin: 40px 25% 0;
}

p.btn-intro {
    top: 44%;
    margin-top: -60px;
    position: relative;
	font-family: 'Raleway';
    font-weight: normal;
	text-transform: uppercase;
}

.huge-button-link h2 {
    position: relative;
    top: 43%;
	font-family: 'Raleway';
    font-weight: normal;
}

.huge-button-link p.view {
    position: relative;
	text-transform:capitalize;
    top: 50%;
    margin: 15px 0 0;
    text-decoration: underline 1important;
    font-family: Georgia;
    font-style: italic;
    font-size: 18px;
}

@media (min-width:768px){
	.huge-button {
		height:50%;
	}
	p.btn-intro {
    	top: 44%;
	}
	.huge-button-link h2 {
    	top: 44%;
	}
	a.huge-button-link .line {
        top: 50%;
        margin: 40px 50% 0;
    }
	.huge-button-link p.view {
    	top: 50%;
        margin: 25px 0 0;
	}
}

/** Search Vars **/

.searchVars a.btn,
.searchVars button {
	line-height: 1em;
    padding-top: 5px;
	font-size: 14px;
	border-radius: 3px;
	border:1px solid rgba(0,0,0,.2);
    color: #FFF;
}
.searchVars button span {
	height: 14px;
	width:14px;
	display: inline-block;
	color: rgba(255,255,255,.7);
    overflow: hidden;
	border-radius: 50%;
	margin:-1px -2px -2px 3px;
	/*border:1px solid rgba(0,0,0,.2);*/
}


/** Properties **/

.feat-prop-container {
	background: #FFF;
    border: 1px solid #999;
    margin-bottom: 30px;
}

.feat-prop-info {
    padding: 10px;
    text-align: center;
}

.feat-prop-section.price {
    margin-top: 23px;
    border-top: 1px solid #ddd;
    text-align: center;
}

.feat-prop-info span.price {
    display: inline-block;
    background: #FFF;
    padding: 0 12px;
    font-size: 1.5em;
    line-height: 1em;
    text-align: center;
    font-family: 'Raleway';
    font-weight: bold;
    color: #C41230;
}

.feat-prop-info svg, .feat-prop-info img.icon {
    height: auto;
    margin: 0;
    border: 0;
    color: #999;
    fill: none;
    stroke: #B0AA99;
    stroke-width: 10;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    width: 25px;
    max-width: 100%;
}

.feat-prop-info .icon-data {
    display: block;
}

.feat-prop-info span.icon-label {
    font-size: .6em;
    font-family: 'Raleway';
    display: block;
}

.feat-prop-section.price p {
    margin-top: -14px !important;
}

.feat-prop-section .icon-data {
    display: block;
    color: #555;
    font-size: 1em;
}

.feat-prop-section span.mlsnum {
    font-size: .7em;
    font-family: 'Open Sans';
    margin: 0;
    padding: 5px 0 0;
    color: #999;
    display: block;
}

.feat-prop-photo img {
	width: 103%;
    height: auto;
    margin: -0.5% 0 0 -0.5%;
}

.main-image {
    border-bottom: 1px solid #dedede;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover !important;

    &[lazy=loading]{
        background-size: auto !important;
    }

    img {
        height: auto !important;
    }
}

#featured-properties {
	background:#e3e0d3;
	min-height: 300px;
    padding: 40px 0;
}

#featured-properties h2 {
    font-family: 'Raleway';
    color:#4d4b47;
    text-align:center;
}

a.favorite,
a.listing-link {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 30px;
    left: 15px;
    right: 15px;
    transition: all ease-out .5s;
}

a.listing-link:hover {
	background:rgba(0,0,0,.1);
}

a.favorite {
    top:1px;
    right:20px;
    left: auto;
    bottom: auto;
    height:30px;
    width:30px;
    z-index: 4;
    padding: 0 2px 1px;
    border-radius: 0 0 5px 5px;
    background-color: rgba(0,0,0,.2);
    cursor: pointer;

    &:hover {
        background-color: rgba(0,0,0,.5);
    }
}

a.favorite path {
    fill:#FFF !important;
}

a.favorite.active path,
a.favorite:hover path {
    fill:#C1272D !important;
}

.status-flag {
    position: absolute;
    z-index: 3;
    top: 6px;
    display: inline-block;
    padding: 3px 8px;
    background-color: rgba(0,0,0,.75);
    color: #FFF;
    font-size: .9em;
    text-transform: uppercase;
    text-align: left;
    border-radius: 0 4px 4px 0;
    left: 0;
}

span.icon img {
    max-width: 30px;
    max-height: 23px;
}

/*** Mid & Building ***/
#mid {
    background: #f7f6f3;
    flex-grow: 1;
}

.home #mid .entry-content {
	padding: 40px 0 65px 0;
}

.page .entry-content {
	padding:40px 0;
}

.page .entry-content.full-width {
	padding:20px 0;
}

#featured-properties h2,
#mid h2 {
	color:#c41230;
	/*font-size: 32px;*/
    font-family: 'Raleway';
    margin-top:1rem;
}

#featured-properties .row {
    padding-top: 1rem;
}

#mid p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

#mid * {
	font-family: 'Raleway' !important;
}

#mid a:hover {
    color:#562729;
}

#mid a {
    color:#C50A2C;
    text-decoration: underline;
}

.hidden {
     display:none;
}

#mid a.btn:hover,
#mid a.btn {
    color:#FFF;
    text-decoration: none;
}

p#search-nums {
    padding: 13px;
    font-size: .9em;
    line-height: 1em;
    color: rgba(0,0,0,.5);
}

.feat-prop.map .feat-prop-container {
    background: #FFF;
    border: none;
    margin-bottom: 0;
    width: 100%;
}

#map .gm-style-iw {
    top: 1px !important;
    left: 1px !important;
    width:300px !important;
}

#map .gm-style-iw + div {
    padding: 0;
    border-radius: 30% 0 0 30%;
    background: #FFF;
    background-position: center;
    left: -19px;
    top: 7px !important;
    opacity: 1 !important;
    border: 1px solid #999;
    height: 23px !important;
    width: 20px !important;
}

#map .gm-style-iw + div img {
    left: 1px !important;
    top: -332px !important;
}

#map .gm-style-iw, .gm-style-iw > div {
    width: 330px !important;
    max-width: none !important;
}

#map .gm-style-iw > div > div {
    overflow:hidden !important;
}

span.icon-data {
    max-height: 24px;
    overflow: hidden;
}

/* Single Property */
.property-left {}
.property-right {
	padding:30px !important;
}

.property-left .embed-responsive {
    margin:0 -15px;
}

.feat-prop.map a.listing-link {
    left: 0;
    right: 0;
}

#map img[src="http://maps.gstatic.com/mapfiles/api-3/images/mapcnt3.png"] {
    display: none;
}

/* Agents */
#mid h3.agent-name {
    margin: 20px 0 0;
}
#mid .agent-info p.title {
    margin: 0 0 15px;
    padding: 0;
}
#mid .agent-info p.email {
    margin: 15px 0 0;
    padding: 0;
}
#mid .agent-info .line {
    transition: all ease-out .5s;
    width: 30%;
    height: 1px;
    background-color: #C41230;
    margin: 0 auto;
}
#mid .agent-info:hover .line {
    width: 60%;
}
#mid .agent-info {
    min-height: 300px;
}
.agent-thumb {
    height: 150px;
    overflow: hidden;
}
.agent-thumb img {
    border: 1px solid rgba(0,0,0,.4);
    height: auto;
    width: 150px;
}

#mid .agent-info.photo p.phone {
    margin: 0;
    padding: 0;
}

#mid .agent-info.full .line {
    transition: all ease-out .5s;
    width: 100%;
    height: 1px;
    background-color: #C41230;
    margin: 0 auto 30px;
}

#mid .agent-info.photo .line {
    margin: 20px auto 0;
}

#mid .agent-info.photo {
    min-height: 0;
}

#mid .entry-content.agent {
    padding: 30px 0;
}

p.agent-button {
    margin: 5px;
}

#mid .agent-info .datalabel {
    font-size: .6em;
    text-transform: uppercase;
}

#lightbox .modal-content {
    display: inline-block;
    text-align: center;
}

#lightbox .close {
    opacity: 1;
    color: rgb(255, 255, 255);
    background-color: rgb(25, 25, 25);
    padding: 5px 8px;
    border-radius: 30px;
    border: 2px solid rgb(255, 255, 255);
    position: absolute;
    top: -15px;
    right: -15px;

    z-index:1032;
}

.carousel-control {
  z-index:20;
}

.carousel-control-next-icon {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #FFF;
  position: absolute;
  bottom: 0;
  margin: 6px;
  right:0;
}

.carousel-control-prev-icon {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #FFF;
  position: absolute;
  bottom: 0;
  margin: 6px;
}

.carousel-indicators {
    position: absolute;
    bottom: 0 !important;
    left: 50%;
    z-index: 15;
    padding: 4px;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    margin-left: -50%;
    text-align: center;
    list-style: none;
    background: rgba(0,0,0,.4);
}

.carousel-caption p {
    background: rgba(0,0,0,.4);
    margin: 0;
    padding: 5px;
    border-radius: 10px;
    font-size: .9em !important;
}

/** Contact Form **/
.request-info-form {
    background-color: #333;
    padding: 30px 0 40px;
}

/* Extra small devices (portrait phones, less than 544px) */
/* No media query since this is the default in Bootstrap */

.fieldset-user_email,
.fieldset-username,
.fieldset-last_name,
.fieldset-first_name,
.fieldset-username_email,
.fieldset-password,
.fieldset-comments,
p.submit-btn,
p.wpum-submit,
.wpum-helper-links,
p.login-username,
p.login-password,
p.login-remember,
p.login-submit,
p.wpum-submit,
p.wpum-message,
p.the-message,
.fieldset-password_2,
.fieldset-password_repeat,
.fieldset-user_url,
.fieldset-description,
.fieldset-display_name,
#wpum-psw-footer {
    width: 100%;
    padding: 5px 15px;
}

p.wpum-submit {
  clear:both;
}



#wpum-psw-footer {display: none;}

p.the-message,
.wpum-success {
    background-color: #dff0d8;
    border: 1px solid #5cb85c;
    margin: 0 15px 15px;
}

.area-content h1 {
    margin-bottom: 32px;
}

.g-recaptcha {
    clear: both;
}

.form-horizontal label {
    text-align: right;
}

h2.entry-title a {
    font-size: .75em;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

#mid .entry-content table {
    padding: 0 !important;
    border: 1px solid rgba(0,0,0,.1);
    width: 100%;
    background-color: #FFF;
    margin: 5px 0 20px;
}

#mid .entry-content table td {
    padding: 5px 10px !important;
    margin: 0 !important;
}

#mid .entry-content table tr {
    border-bottom: 1px solid rgba(227,224,211,.6);
}


#mid .entry-content table tr:nth-child(odd) {
    background-color: rgba(227,224,211,.3);
}

#mid .entry-content table tr:last-child {
    border-bottom:0px;
}

#mid p.author {
    font-size: .9em;
    margin: 0 0 20px;
    padding: 0;
}

.blog-post {
    padding: 10px 0 17px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.blog-post .featured-photo img {
    width: 100%;
}

#wpum-show-password {
    margin-right: 6px;
}

.advanced-menu {
    margin: 9px 0;
}

/* Large devices (desktops, 1092px and up) */
@media (min-width: 1092px) {
	/*dropdowns*/
	#top nav li.menu-item ul.sub-menu {
		z-index: 999999999999;
		display: none;
		position: absolute;
		border-radius:0;
		text-align: left;
		border:0;
	}
	#top nav li.menu-item ul.sub-menu li {
		width:100%;
		text-align: left;
	}
	#top nav li.menu-item ul.sub-menu li a {
		font-size: 13px;
		display: block;
		padding:8px 12px;
		text-align: left;
		color:#FFF;
	}
	#top nav li.menu-item ul.sub-menu li a:hover {
		background-color: #562729;
	}
	#top nav li.menu-item:hover ul.sub-menu {
		display: block;
		width:250px;
		background-color: #3d1b1c;
		text-align: left;
		margin: 0;
		padding: 0;
	}
}

/* Extra large devices (large desktops, 1300px and up) */
@media (min-width: 1300px) {

}

.main-prop-photo img.img-responsive {
    width: 102%;
    margin: -1%;
}

#mid p.rets-disclaimer {
    font-size: .8em;
    margin: 15px 0;
    text-align: center;
}

/*SVGs
.st0{fill:none;stroke:#B0AA99;stroke-width:10;stroke-linejoin:round;stroke-miterlimit:10;}*/

// .custom-control-label::before {
//     position: absolute;
//     top: .15rem;
//     left: 0rem;
//     display: block;
//     width: 1.25rem;
//     height: 1.25rem;
//     pointer-events: none;
//     content: "";
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     background-color: #fff;
//     border: 1px solid #ccc;
//     border-radius: 3px;
// }

// .custom-control-label::after {
//     position: absolute;
//     top: .15rem;
//     left: 0rem;
//     display: block;
//     width: 1.25rem;
//     height: 1.25rem;
//     content: "";
//     background-repeat: no-repeat;
//     background-position: center center;
//     background-size: 50% 50%;
//     border: 1px solid #ccc;
//     border-radius: 3px;
// }

// .custom-control-input:checked ~ .custom-control-label::before {
//     color: #fff;
//     background-color: #C50A2C;
//     border: none;
// }

// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
//     background-color: #C50A2C;
//     border: none;
// }

// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
// }

// .custom-checkbox {
//     display: block;
//     margin: .25rem 0;
// }

// .custom-control + .custom-control {
//     margin-left: 0;
// }

// @media screen and (min-width:1070px){
//     .custom-checkbox {
//         display: inline-block;
//         margin: .0;
//     }
//     .custom-control + .custom-control {
//         margin-left: 1rem;
//     }
// }


#navbar-header.show {
    display: block !important;
}